<template>
  <v-container
    fluid
    class="width-100 px-0 py-0"
    v-if="getPermission('setting:view')"
  >
    <v-layout v-if="false">
      <v-flex>
        <h3
          class="font-weight-700 mb-4 font-size-24 custom-headline color-custom-blue"
        >
          Customer Asset
        </h3>
      </v-flex>

      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-row>
        <v-col md="12">
          <v-card
            flat
            class="custom-setting-height custom-grey-border remove-border-radius"
          >
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="6" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Customer Asset #
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="6" class="text-right py-0">
                    <v-btn
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateCustomerAssetSetting"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="2" class="pb-4">
                  <label>Prefix</label>
                  <v-text-field
                    v-model.trim="setting.prefix"
                    dense
                    filled
                    placeholder="Prefix"
                    solo
                    flat
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="2" class="pb-4">
                  <label>Next Number</label>
                  <v-text-field
                    v-model.trim="setting.next_number"
                    dense
                    filled
                    placeholder="Next Number"
                    solo
                    flat
                    type="number"
                    min="0"
                    max="999"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="2" class="pb-4">
                  <label>Number Length</label>
                  <v-text-field
                    v-model.trim="setting.number_length"
                    dense
                    filled
                    placeholder="Number Length"
                    solo
                    flat
                    type="number"
                    min="0"
                    max="15"
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-asset-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        description: null,
      },
    };
  },
  methods: {
    getCustomerAssetSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/customer-asset" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateCustomerAssetSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/customer-asset", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getCustomerAssetSetting();
  },
};
</script>
