<template>
  <v-container
    fluid
    class="width-100 px-0 py-0"
    v-if="getPermission('setting:view')"
  >
    <v-layout v-if="false">
      <v-flex>
        <h3
          class="font-weight-700 mb-4 font-size-24 custom-headline color-custom-blue"
        >
          Product
        </h3>
      </v-flex>

      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="12">
        <v-card
          flat
          class="custom-setting-height custom-grey-border remove-border-radius"
        >
          <v-card-title class="headline grey lighten-4">
            <v-row>
              <v-col md="6" class="py-0 my-auto">
                <h3
                  class="font-weight-700 custom-headline color-custom-blue"
                  style="margin: 3.5px 0px"
                >
                  Product #
                </h3>
              </v-col>
              <template v-if="getPermission('setting:update')">
                <v-col md="6" class="text-right py-0">
                  <v-btn
                    small
                    class="text-white"
                    color="cyan"
                    v-on:click="updateProductSetting"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Update</span>
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-card-title>
          <v-card-text
            class="p-6 font-size-16"
            :class="{
              'pointer-events-none': !getPermission('setting:update'),
            }"
          >
            <v-row>
              <v-col md="2" class="pb-4">
                <label>Prefix</label>
                <v-text-field
                  v-model.trim="setting.prefix"
                  dense
                  filled
                  placeholder="Prefix"
                  solo
                  flat
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                ></v-text-field>
              </v-col>
              <v-col md="2" class="pb-4">
                <label>Next Number</label>
                <v-text-field
                  v-model.trim="setting.next_number"
                  dense
                  filled
                  placeholder="Next Number"
                  solo
                  flat
                  type="number"
                  min="0"
                  max="999"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                ></v-text-field>
              </v-col>
              <v-col md="2" class="pb-4">
                <label>Number Length</label>
                <v-text-field
                  v-model.trim="setting.number_length"
                  dense
                  filled
                  placeholder="Number Length"
                  solo
                  flat
                  type="number"
                  min="0"
                  max="15"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                ></v-text-field>
              </v-col>
              <v-col md="12" class="pb-4" v-if="false">
                <table class="width-100">
                  <tr>
                    <td class="py-2">
                      When you want to hold stock
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Default tax rate will appear<br />on new invoices,
                          jobs and quotations</span
                        >
                      </v-tooltip>
                    </td>
                    <td class="py-2">
                      <v-radio-group
                        class="my-0"
                        hide-details
                        row
                        mandatory
                        v-model="setting.hold_stock_level"
                      >
                        <v-radio
                          v-for="(row, index) in holdStockList"
                          :key="index"
                          :label="row.text"
                          :value="row.value"
                          :disabled="pageLoading"
                          color="cyan"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-2">
                      When you want to release stock
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Default tax rate will appear<br />on new invoices,
                          jobs and quotations</span
                        >
                      </v-tooltip>
                    </td>
                    <td class="py-2">
                      <v-radio-group
                        class="my-0"
                        hide-details
                        row
                        mandatory
                        v-model="setting.release_stock_level"
                      >
                        <v-radio
                          v-for="(row, index) in releaseStockList"
                          :key="index"
                          :label="row.text"
                          :value="row.value"
                          :disabled="pageLoading"
                          color="cyan"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-2">
                      Accept overstock transactions
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Default tax rate will appear<br />on new invoices,
                          jobs and quotations</span
                        >
                      </v-tooltip>
                    </td>
                    <td class="py-2">
                      <v-radio-group
                        class="my-0"
                        hide-details
                        row
                        mandatory
                        v-model="setting.accept_over_stock"
                      >
                        <v-radio
                          label="Yes"
                          :value="1"
                          :disabled="pageLoading"
                          color="cyan"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="0"
                          :disabled="pageLoading"
                          color="cyan"
                        ></v-radio>
                      </v-radio-group>
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          flat
          class="custom-grey-border remove-border-radius mt-4"
          v-if="false"
        >
          <v-card-title class="headline grey lighten-4">
            <v-row>
              <v-col md="6" class="py-0 my-auto">
                <h3
                  class="font-weight-700 custom-headline color-custom-blue"
                  style="margin: 3.5px 0px"
                >
                  Product Description
                </h3>
              </v-col>
              <template v-if="getPermission('setting:update')">
                <!-- <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateProductSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col> -->
              </template>
            </v-row>
          </v-card-title>
          <v-card-text
            class="p-6 font-size-16"
            :class="{
              'pointer-events-none': !getPermission('setting:update'),
            }"
          >
            <v-row>
              <v-col md="12" class="pb-4">
                <v-textarea
                  v-model.trim="setting.description"
                  auto-grow
                  dense
                  filled
                  placeholder="Description"
                  solo
                  flat
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  row-height="30"
                  color="cyan"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card flat class="custom-grey-border remove-border-radius mt-4">
          <v-card-title class="headline grey lighten-4">
            <v-row>
              <v-col md="4" class="py-0 my-auto">
                <h3
                  class="font-weight-700 custom-headline color-custom-blue"
                  style="margin: 3.5px 0px"
                >
                  Part/Product Tax Name
                </h3>
              </v-col>
              <template v-if="getPermission('setting:update')">
                <v-col md="8" class="text-right py-0">
                  <div class="d-flex justify-content-end">
                    <v-btn
                      depressed
                      small
                      class="white--text"
                      v-on:click="taxNameDialogOpen"
                      color="cyan"
                    >
                      <span class="font-size-16 font-weight-600">Create</span>
                    </v-btn>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-card-title>
          <v-card-text
            class="p-6 font-size-16"
            :class="{
              'pointer-events-none': !getPermission('setting:update'),
            }"
          >
            <v-row>
              <v-col md="12" class="pb-4">
                <v-simple-table
                  class="simple-table"
                  fixed-header
                  style="min-height: 200px"
                  :height="paginatedtaxName?.length > 0 ? 'auto' : '100px'"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="simple-table-th px-1" width="50">#</th>
                        <th class="simple-table-th px-1" width="500">
                          Tax Name
                        </th>
                        <th class="simple-table-th" width="150">Created At</th>
                        <th class="simple-table-th" width="70">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="paginatedtaxName?.length > 0">
                      <tr
                        v-for="(taxname, index) in paginatedtaxName"
                        :key="index"
                        class="alternate-listing-row"
                      >
                        <td width="50" class="simple-table-td">
                          {{ (currentPage - 1) * itemsPerPage + index + 1 }}
                        </td>
                        <td width="100" class="simple-table-td text-capitalize">
                          <template v-if="taxname?.value">
                            {{ taxname?.value }}
                          </template>
                          <em v-else class="text-muted"> no name </em>
                        </td>
                        <td class="simple-table-td">
                          <template v-if="taxname?.added_at">
                            <div class="custom-table-content max-width-150px">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mx-2 my-1 custom-grey-border"
                                    color="blue-grey darken-1"
                                    text-color="white"
                                    style="height: 25px !important"
                                  >
                                    <v-avatar left>
                                      <v-icon>mdi-clock</v-icon>
                                    </v-avatar>
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      {{ taxname.added_at }}
                                    </p>
                                  </v-chip>
                                </template>
                                <span>{{ taxname.added_at }}</span>
                              </v-tooltip>
                            </div>
                          </template>
                          <em v-else class="text-muted"> </em>
                          <!-- <TableActivity
                                v-if="!lodash.isEmpty(taxname?.added_by)"
                                :data="taxname"
                              >
                                <template v-slot:date_time>
                                  {{ taxname.added_at }}
                                </template>
                                <template v-slot:format_date_time>
                                  {{ formatDateTime(taxname.added_at) }}
                                </template>
                              </TableActivity> -->
                        </td>

                        <td class="simple-table-td">
                          <v-icon
                            color="cyan"
                            size="20"
                            v-on:click="updateTaxNameType(taxname)"
                          >
                            mdi-pencil</v-icon
                          >
                          <v-icon
                            color="red"
                            class="ml-2"
                            size="20"
                            v-on:click="deleteTaxName(taxname)"
                          >
                            mdi-delete</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tr v-else>
                      <td colspan="5" class="py-4">
                        <p
                          class="m-0 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no tax name at the moment.
                        </p>
                      </td>
                    </tr>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row
              justify="end"
              class="align-center pagination_btn listing-footer px-2"
            >
              <p class="m-0 listing-footer-count">
                Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                {{ Math.min(currentPage * itemsPerPage, taxNameList.length) }}
                of {{ taxNameList.length }} entries
              </p>
              <v-spacer></v-spacer>
              <v-pagination
                v-model="currentPage"
                :length="pageCount"
                total-visible="7"
                color="cyan"
                @input="handlePageChange"
              ></v-pagination>
            </v-row>
          </v-card-text>
        </v-card>

        <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
          <template v-slot:title> Confirmation </template>
          <template v-slot:body>
            <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
              <v-container class="px-10" fluid>
                <h3 class="font-weight-500 text-center">
                  Tax Name already exists in another transaction. Any updates
                  will be reflected in a new transaction.
                </h3>
              </v-container>
            </v-form>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="confirm_loading"
              v-on:click="confirm_dialog = false"
              class="mx-2 custom-grey-border custom-bold-button"
            >
              No! Close
            </v-btn>
            <v-btn
              v-on:click="updateTaxNameSetting()"
              :disabled="!formValid || confirm_loading"
              :loading="confirm_loading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Yes! Continue
            </v-btn>
          </template>
        </Dialog>
        <Dialog :commonDialog="taxNameDialog" :dialog-width="850">
          <template v-slot:title>
            <template v-if="taxNamePayload?.id > 0">Edit</template
            ><template v-else>Add</template> Tax Name
          </template>
          <template v-slot:body>
            <v-container fluid>
              <v-form
                ref="ticketCollecttionFormSkills"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="updateTaxNameSetting"
              >
                <v-row>
                  <v-col md="12">
                    <label class="my-auto fw-500 required mb-1"
                      >Tax Name
                    </label>
                    <TextField
                      id="tax-name"
                      dense
                      filled
                      placeholder="Tax Name"
                      solo
                      flat
                      counter="50"
                      v-model.trim="taxNamePayload.name"
                      color="cyan"
                      :maxlength="250"
                      :rules="[
                        validateRules.required(taxNamePayload.name, 'Name'),
                      ]"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :loading="pageLoading"
              :disabled="pageLoading || !formValid"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="updateTaxNameSettingType(is_exist)"
            >
              Save
            </v-btn>
            <v-btn
              depressed
              :disabled="pageLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="taxNameDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <DeleteDialog :delete-dialog="deleteTaxNameDialog">
          <template v-slot:title>Delete Confirmation</template>
          <template v-slot:text>
            <h3 class="font-size-18 font-weight-600 delete-text">
              Are you sure you want to delete this tax name ?
            </h3>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :disabled="deleteTaxNameLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="deleteTaxNameDialog = false"
              >No! Cancel</v-btn
            >
            <v-btn
              depressed
              :disabled="deleteTaxNameLoading"
              :loading="deleteTaxNameLoading"
              color="red lighten-1 white--text"
              v-on:click="deleteTaxNameConfirm()"
              class="mx-2 custom-grey-border custom-bold-button"
              >Yes! Delete</v-btn
            >
          </template>
        </DeleteDialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
// import TableActivity from "@/view/pages/partials/Table-Activity.vue";
export default {
  name: "profile-product-setting",
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      holdStockList: [
        {
          value: "quotation",
          text: "Quotation",
        },
        {
          value: "job",
          text: "Job",
        },
        {
          value: "none",
          text: "None",
        },
      ],
      releaseStockList: [
        {
          value: "job",
          text: "Job",
        },
        {
          value: "invoice",
          text: "Invoice",
        },
        {
          value: "none",
          text: "None",
        },
      ],
      deleteTaxNameLoading: false,
      pageLoading: true,
      taxNamePayload: {
        name: null,
        is_active: 1,
      },
      taxPageLoading: false, // or your actual loading state variable
      itemsPerPage: 10,
      taxname_id: 0,
      currentPage: 1,
      is_exist: 0,
      confirm_dialog: false,
      confirm_loading: false,
      deleteTaxNameDialog: false,
      taxNameDialog: false,
      taxNameList: [],
      dataLoading: false,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        description: null,
      },
    };
  },
  components: {
    Dialog,
    DeleteDialog,
    // TableActivity,
  },
  methods: {
    deleteTaxName(data) {
      this.taxname_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.deleteTaxNameDialog = true;
    },
    deleteTaxNameConfirm() {
      const _this = this;
      _this.deleteTaxNameLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `setting/tax-name/${this.taxname_id}`,
        })
        .then(() => {
          _this.deleteTaxNameLoading = false;
          _this.deleteTaxNameDialog = false;
          _this.getProductSetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteTaxNameLoading = false;
        });
    },

    updateTaxNameType(param) {
      const _this = this;
      _this.is_exist = param.is_exist ? 1 : 0;
      _this.taxNameDialog = true;

      setTimeout(function () {
        _this.taxNamePayload = {
          id: _this.lodash.toSafeInteger(param.id),
          name: param?.value ? param.value : null,
          is_active: param.is_active ? param.is_active : 1,
        };
      }, 500);
    },
    updateTaxNameSettingType(type) {
      if (type == 0) {
        this.updateTaxNameSetting();
      } else {
        this.confirm_dialog = true;
      }
    },
    updateTaxNameSetting() {
      const _this = this;
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.pageLoading = false;
        }
        return false;
      }

      _this.pageLoading = true;
      _this.dataLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "setting/tax-name",
          data: _this.taxNamePayload,
        })
        .then(() => {
          _this.getProductSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
          _this.pageLoading = false;
          _this.taxNameDialog = false;
        });
    },

    taxNameDialogOpen() {
      this.taxNamePayload = {
        name: null,
        is_active: 1,
      };
      this.taxNameDialog = true;
    },
    getProductSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/product" })
        .then(({ data }) => {
          _this.setting = data;
          _this.taxNameList = data.tax_names;
          _this.confirm_dialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateProductSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/product", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    handlePageChange(page) {
      this.taxPageLoading = true;
      this.currentPage = page;
      setTimeout(() => {
        this.taxPageLoading = false;
      }, 1000);
    },
  },
  mounted() {
    this.getProductSetting();
  },
  computed: {
    pageCount() {
      return Math.ceil(this.taxNameList.length / this.itemsPerPage);
    },
    paginatedtaxName() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.taxNameList.slice(start, end);
    },
  },
};
</script>
