<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-700 mb-4 font-size-24 custom-headline color-custom-blue"
        >
          Company Settings
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-menu offset-y left>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  depressed
                  :disabled="pageLoading"
                  class="mr-4 custom-bold-button"
                  color="cyan white--text"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  {{ entity.name }}
                </v-btn>
              </template>
              <span>Entity</span>
            </v-tooltip>
          </template>
          <v-list disabled>
            <v-list-item
              v-for="(item, index) in entityList"
              :key="index"
              link
              v-on:click="updateEntity(item)"
              disabled
            >
              <v-list-item-title class="font-weight-600">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey sticky-header lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Company Details
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  depressed
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateCompanySetting"
                  :disabled="!formValid || pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-form
            ref="companyForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateCompanySetting"
          >
            <v-row>
              <v-col cols="3" class="text-center">
                <div class="">
                  <div>
                    <v-img
                      class="mx-auto"
                      contain
                      width="250"
                      :src="setting.company_logo"
                    ></v-img>
                  </div>
                  <div class="font-weight-700 mt-2 my-5">
                    (Standard dimension:450×450)
                  </div>
                  <v-spacer></v-spacer>
                  <div class="font-weight-700 mt-2 ml-2">
                    <span>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            v-on:click="changeImage"
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="cyan"
                            class="text-white"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                          >
                            <span class="font-size-14">Change</span>
                          </v-btn>
                        </template>
                        <span>Click here to change logo</span>
                      </v-tooltip>
                    </span>
                    <div class="d-none">
                      <v-file-input
                        ref="uploadImage"
                        v-on:change="uploadCompanyLogo"
                        :rules="imageRules"
                        accept="image/png, image/jpeg, image/jpg"
                      ></v-file-input>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="7">
                <v-row>
                  <v-col md="6" class="pb-4">
                    <label
                      class="font-size-16 font-weight-500 mt-0 required"
                      for="company_name"
                    >
                      Company Name
                    </label>
                    <TextField
                      v-model.trim="setting.company_name"
                      dense
                      filled
                      placeholder="Company name"
                      :rules="[
                        validateRules.required(
                          setting.company_name,
                          'Company Name'
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :counter="50"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></TextField>
                  </v-col>
                  <v-col md="6" class="pb-4">
                    <label
                      class="font-size-16 font-weight-500 mt-0 required"
                      for="phone_number"
                    >
                      Phone Number
                    </label>
                    <!-- <v-text-field
                v-model.trim="setting.phone_number"
                dense
                filled
                placeholder="Phone number"
                :rules="[validateRules.required(setting.phone_number, 'Phone number')]"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field> -->
                    <PhoneTextField
                      v-model.trim="setting.phone_number"
                      v-on:blur="isFocusedN = false"
                      v-on:focus="isFocusedN = true"
                      required
                    >
                    </PhoneTextField>
                  </v-col>
                  <v-col md="6" class="pb-4">
                    <label
                      class="font-size-16 font-weight-500 mt-0"
                      for="whatsapp_number"
                    >
                      Whatsapp number
                    </label>
                    <PhoneTextField
                      v-model.trim="setting.whatsapp_number"
                      v-on:blur="isFocusedN = false"
                      v-on:focus="isFocusedN = true"
                    >
                    </PhoneTextField>
                    <!-- <v-text-field
                v-model.trim="setting.whatsapp_number"
                dense
                filled
                placeholder="Whatsapp number"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field> -->
                  </v-col>
                  <v-col md="6" class="pb-4">
                    <label
                      class="font-size-16 font-weight-500 mt-0"
                      for="Website"
                    >
                      Website
                    </label>
                    <TextField
                      v-model.trim="setting.website_url"
                      dense
                      filled
                      placeholder="Website URL"
                      solo
                      flat
                      hide-details
                      :counter="50"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></TextField>
                  </v-col>
                  <v-col md="6" class="pb-4">
                    <label
                      class="font-size-16 font-weight-500 mt-0 required"
                      for="email_address"
                    >
                      Email Address
                    </label>
                    <TextField
                      v-model.trim="setting.email_address"
                      :rules="[
                        validateRules.required(
                          setting.email_address,
                          'Email Address'
                        ),
                        validateRules.validEmail(
                          setting.email_address,
                          'Email Address'
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Email address"
                      solo
                      flat
                      :counter="50"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></TextField>
                  </v-col>
                  <v-col md="12" class="">
                    <label
                      class="font-size-17 font-weight-700 mt-2 grey lighten-4 pa-2 d-block"
                      for="email_address"
                    >
                      Company Address
                    </label>
                  </v-col>

                  <v-col md="6" sm="12" class="pb-4">
                    <label class="font-size-16 font-weight-500">Country </label>

                    <v-autocomplete
                      :items="countryList"
                      v-model="setting.country"
                      hide-details
                      flat
                      solo
                      dense
                      item-value="value"
                      item-text="text"
                      placeholder="Country"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="countryCheck($event)"
                      class="pt-0 mt-0"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6" sm="12" class="pb-4">
                    <label class="font-size-17 font-weight-700"
                      >Search Address
                    </label>
                    <SearchAddress
                      min-height="32"
                      variant="outlined"
                      density="compact"
                      hide-details
                      :country="setting.country"
                      :country-code="country_code"
                      @update:address="(data) => setAddress(data)"
                      :placeholder="
                        setting.country == 'singapore'
                          ? 'Search Address'
                          : 'Search Address'
                      "
                    />
                  </v-col>

                  <v-col md="6" class="pb-4">
                    <label class="font-size-16 font-weight-500"
                      >Address Line 1
                    </label>
                    <TextField
                      v-model.trim="setting.street_1"
                      dense
                      filled
                      placeholder="Address Line 1"
                      solo
                      flat
                      hide-details
                      :counter="100"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></TextField>
                  </v-col>
                  <v-col md="6" class="pb-4">
                    <label class="font-size-16 font-weight-500 required"
                      >Address Line 2
                    </label>
                    <TextField
                      v-model.trim="setting.street_2"
                      dense
                      filled
                      placeholder="Address Line 2"
                      :rules="[
                        validateRules.required(
                          setting.street_2,
                          'Address Line 2'
                        ),
                      ]"
                      solo
                      flat
                      :counter="100"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></TextField>
                  </v-col>
                  <v-col md="6" class="pb-4">
                    <label class="font-size-16 font-weight-500">Unit No </label>
                    <TextField
                      v-model.trim="setting.landmark"
                      dense
                      filled
                      placeholder="Unit No"
                      solo
                      flat
                      :counter="100"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></TextField>
                  </v-col>
                  <v-col md="6" class="pb-4">
                    <label class="font-size-16 font-weight-500 required"
                      >Postal Code
                    </label>
                    <v-text-field
                      v-model.trim="setting.zip_code"
                      dense
                      filled
                      placeholder="Postal Code"
                      :rules="[
                        validateRules.required(setting.zip_code, 'Postal Code'),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-text class="py-6 px-0">
                    <div>
                      <div class="px-3">
                        <v-img
                          cover
                          :src="$assetURL('media/books/product-box.png')"
                        ></v-img>
                        <div class="text-h6 pt-4">Lorem Ipsum text</div>
                        <div class="pt-2 font-size-16 font-weight-medium">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Eum veritatis error ut sed. Lorem, ipsum dolor
                          sit amet consectetur adipisicing elit. Eum veritatis
                          error ut sed.
                        </div>
                      </div>
                    </div>
                    <div class="text-center mt-5" v-if="false">
                      <v-img
                        :src="$assetURL('media/books/product-box.png')"
                      ></v-img>
                      <v-btn
                        depressed
                        class="custom-bold-button text-white mt-8"
                        color="cyan"
                      >
                        Add Product
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col md="12" class="mt-4 pb-4 custom-border-top" v-if="false">
                <h3
                  class="font-weight-700 custom-headline color-custom-blue py-4"
                >
                  Currency Exchange
                </h3>
                <v-row>
                  <v-col md="4">
                    <v-text-field
                      v-model.trim="from_currency"
                      dense
                      filled
                      placeholder="From Currency"
                      solo
                      :readonly="true"
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model.trim="to_currency"
                      dense
                      filled
                      placeholder="To Currency"
                      solo
                      :readonly="true"
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model.trim="setting.exchange_rate"
                      dense
                      filled
                      placeholder="Exchange Rate"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="3"></v-col>
              <v-col
                md="2"
                class="mt-4 pb-4 custom-border-top pt-0"
                style="background-color: aliceblue"
              >
                <h3
                  class="font-weight-700 custom-headline color-custom-blue py-4"
                >
                  Regional Settings
                </h3>
                <v-row>
                  <v-col md="12">
                    <label class="font-size-16 font-weight-600">Date </label>
                    <v-select
                      v-model="setting.date_format"
                      :items="date_format_list"
                      placeholder="Date format"
                      solo
                      flat
                      dense
                      filled
                      hide-details
                      item-color="cyan"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      class="py-0"
                    ></v-select>
                  </v-col>
                  <v-col md="12">
                    <label class="font-size-16 font-weight-600">Time </label>
                    <v-select
                      v-model="setting.time_format"
                      :items="time_format_list"
                      placeholder="Time format"
                      solo
                      flat
                      dense
                      filled
                      hide-details
                      item-color="cyan"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      class="py-0"
                    ></v-select>
                  </v-col>
                  <v-col md="12">
                    <label class="font-size-16 font-weight-600"
                      >First day of the week
                    </label>
                    <v-select
                      v-model="setting.weekday"
                      :items="weekdayList"
                      placeholder="First day of the week"
                      solo
                      flat
                      dense
                      filled
                      hide-details
                      item-color="cyan"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      class="py-0"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                style="background-color: antiquewhite"
                md="5"
                class="mt-4 pb-4 custom-border-top company-business-hour-setting pt-0"
              >
                <h3
                  class="font-weight-700 custom-headline color-custom-blue py-4"
                >
                  Business Hours
                </h3>
                <div>
                  <table width="100%">
                    <tbody>
                      <tr
                        v-for="(day, index) in weekDays"
                        :key="index"
                        :class="{
                          'business-hours-disabled': !day.status,
                        }"
                      >
                        <td width="100" class="font-weight-600 font-size-16">
                          {{ day.title }}
                        </td>
                        <td width="100">
                          <v-switch
                            v-model.trim="day.status"
                            inset
                            hide-details
                            color="cyan"
                          ></v-switch>
                        </td>
                        <td
                          class="px-4"
                          :class="{ 'pointer-events-none': !day.status }"
                        >
                          <TimePickerTemplate
                            v-on:update:time-picker="
                              updateTime('start', index, $event)
                            "
                            :defaultTime="day.start_time"
                            placeholder="Start Time"
                          ></TimePickerTemplate>
                        </td>
                        <td
                          class="px-0"
                          :class="{ 'pointer-events-none': !day.status }"
                        >
                          <TimePickerTemplate
                            v-on:update:time-picker="
                              updateTime('end', index, $event)
                            "
                            :defaultTime="day.end_time"
                            placeholder="End Time"
                          ></TimePickerTemplate>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-col cols="10" class="custom-border-top pb-15">
                <CalendarTemplate internal></CalendarTemplate>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import CalendarTemplate from "@/view/pages/profile/Setting/Calendar";
import moment from "moment-timezone";
import TimePickerTemplate from "@/view/pages/partials/Timepicker";
import { PATCH, QUERY, UPLOAD } from "@/core/services/store/request.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { getConfig } from "@/core/services/local.service";
import ValidationMixin from "@/core/plugins/validation-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import SearchAddress from "@/view/components/Search-Address";
import { cloneDeep } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "profile-company-setting",
  mixins: [ValidationMixin],
  data() {
    return {
      searchAddress: null,
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      pageLoading: true,
      entityList: [],
      entity: {},
      from_currency: "SGD",
      to_currency: "USD",
      country_code: "sg",
      setting: {
        country: "singapore",
        company_name: null,
        phone_number: null,
        whatsapp_number: null,
        website_url: null,
        email_address: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        date_format: null,
        time_format: null,
        weekday: null,
        exchange_rate: null,
      },
      time_format_list: [
        {
          value: "HH:mm",
          text: "24 Hour (" + moment().format("HH:mm") + ")",
        },
        {
          value: "hh:mm A",
          text: "12 Hour (" + moment().format("hh:mm A") + ")",
        },
      ],
      date_format_list: [
        {
          value: "MMMM Do YYYY",
          text: moment().format("MMMM Do YYYY"),
        },
        {
          value: "Do MMMM YYYY",
          text: moment().format("Do MMMM YYYY"),
        },
        {
          value: "MMM Do YY",
          text: moment().format("MMM Do YY"),
        },
        {
          value: "Do MMM YY",
          text: moment().format("Do MMM YY"),
        },
        {
          value: "DD/MM/YYYY",
          text: moment().format("DD/MM/YYYY"),
        },
        {
          value: "MM/DD/YYYY",
          text: moment().format("MM/DD/YYYY"),
        },
        {
          value: "YYYY-DD-MM",
          text: moment().format("YYYY-DD-MM"),
        },
      ],
      weekdayList: [
        {
          value: 1,
          text: "Sunday",
        },
        {
          value: 2,
          text: "Monday",
        },
      ],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Logo size should be less than 2 MB!",
      ],
      weekDays: [],
    };
  },
  methods: {
    changeImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    uploadCompanyLogo(param) {
      const _this = this;
      let formData = new FormData();
      _this.pageLoading = true;
      formData.append("files[]", param, param.name);
      _this.$store
        .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
        .then(async ({ data }) => {
          let uploaded_id = 0;
          for (let i = data.length - 1; i >= 0; i--) {
            _this.setting.company_logo = data[i].file.url;
            uploaded_id = data[i]?.id;
            break;
          }
          await _this.updateCompanyLogo(uploaded_id);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateCompanyLogo(uploaded_id = 0) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(PATCH, {
            url: "setting/branding",
            data: {
              company_logo: _this.setting.company_logo,
              entity: _this.entity.id,
              uploaded_id: uploaded_id,
            },
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            _this.getCompanySetting();
          });
      });
    },
    countryCheck(data) {
      let code = "";
      code = this.lodash.find(this.countryList, function (row) {
        if (row.value == data) {
          return row.country_code;
        } else {
          return false;
        }
      });

      this.country_code = code.country_code;
      this.setting.zip_code = null;
      this.setting.street_1 = null;
      this.setting.street_2 = null;
      // this.setting.unit_no = null;
      this.setting.landmark = null;
      // this.setting.longitude = null;
      // this.setting.latitude = null;
    },
    setAddress(e) {
      this.setting.zip_code = cloneDeep(e.postal_code);
      this.setting.street_1 = e.address1;
      this.setting.street_2 = e.address2;
      this.setting.landmark = e.unit_number;
      // this.propertyAddress.longitude = e.longitude;
      // this.propertyAddress.latitude = e.latitude;
    },

    getCompanySetting() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "setting/company",
          data: { entity: this.entity.id },
        })
        .then(({ data }) => {
          _this.setting = data;
          if (!data.country) {
            _this.setting.country = "singapore";
          }
          _this.weekDays = data.business_hours;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateCompanySetting() {
      const _this = this;
      if (!_this.$refs.companyForm.validate()) {
        return false;
      }

      _this.pageLoading = true;
      _this.setting.business_hours = _this.weekDays;
      _this.setting.entity = _this.entity.id;
      _this.$store
        .dispatch(PATCH, { url: "setting/company", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateTime(type, index, value) {
      if (this.weekDays[index]) {
        if (type == "start") {
          this.weekDays[index].start_time = value;
        }
        if (type == "end") {
          this.weekDays[index].end_time = value;
        }
      }
    },
    updateEntity(row) {
      this.entity = row;
      this.pageLoading = true;
      this.getCompanySetting();
    },
  },
  components: {
    TimePickerTemplate,
    CalendarTemplate,
    PhoneTextField,
    SearchAddress,
  },
  mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.$nextTick(() => {
      if (this.entity) {
        this.updateEntity(this.entity);
      }
    });
    this.$refs.companyForm.validate();
  },
  beforeMount() {
    this.entityList = getConfig("entity");
    this.$nextTick(() => {
      this.entity = this.lodash.find(this.entityList, { id: 1 });
    });
  },
};
</script>
