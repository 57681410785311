<template>
  <v-container
    fluid
    class="setting-calender-tab"
    :class="internal ? 'pa-0' : ''"
    v-if="getPermission('setting:view')"
  >
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-700 mb-3 font-size-24 custom-headline color-custom-blue"
        >
          Calendar
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          small
          class="text-white mr-2"
          color="cyan"
          v-on:click="duplicateCalendarDialog"
        >
          <span class="font-size-16 font-weight-600">Duplicate </span>
        </v-btn>
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4 py-2">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                <v-layout class="align-center">
                  <p class="m-0">Holidays</p>
                  <v-select
                    v-model="year"
                    :items="yearList"
                    placeholder="Year"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="px-4"
                    v-on:change="getHolidays"
                  ></v-select>
                </v-layout>
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0 my-auto">
                <v-btn
                  depressed
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="(holidayDialog = true), getTeams()"
                >
                  <span class="font-size-16 font-weight-600">Create</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <div class="custom-listing-table">
            <v-simple-table
              class="simple-table"
              fixed-header
              style="min-height: 200px"
              :height="holidays?.length > 0 ? 'auto' : '100px'"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="simple-table-th px-1" width="50">#</th>
                    <th class="simple-table-th px-1" width="100">Date</th>
                    <th class="simple-table-th px-1" width="350">Name</th>
                    <th class="simple-table-th px-1" width="350">Team</th>
                    <th class="simple-table-th px-1">Description</th>
                    <th class="simple-table-th px-1" width="70">Action</th>
                  </tr>
                </thead>
                <tbody v-if="holidays?.length > 0">
                  <tr
                    v-for="(holiday, index) in holidays"
                    :key="index"
                    class="alternate-listing-row"
                  >
                    <td width="50" class="simple-table-td">{{ index + 1 }}</td>
                    <td width="100" class="simple-table-td">
                      {{ formatDate(holiday.date) }}
                    </td>
                    <td width="100" class="simple-table-td">
                      <template v-if="holiday?.name">{{
                        holiday?.name
                      }}</template>
                      <em v-else class="text-muted"> no name </em>
                    </td>
                    <td width="100" class="simple-table-td text-capitalize">
                      <template v-if="holiday?.team?.length > 0">
                        <span
                          v-for="(team, index) in holiday.holiday_teams"
                          :key="index"
                        >
                          <span class="mr-1"
                            >{{ team?.team_data?.team_name }}({{
                              team?.team_data?.team_members_count
                            }})</span
                          >
                        </span>
                      </template>
                      <em
                        v-if="
                          holiday?.team?.length == 0 &&
                          holiday?.allow_job_assign == 1
                        "
                        class="text-muted"
                      >
                        no team
                      </em>
                      <!--   <span
                        v-if="
                          holiday?.team?.length == 0 &&
                          holiday?.allow_job_assign == 2 &&
                          holiday?.is_for_all_users == 2
                        "
                        class="font-weight-600"
                      >
                        All Users
                      </span> -->
                    </td>
                    <td class="simple-table-td">
                      <template v-if="holiday?.description">
                        <span class="d-flex align-center">
                          {{ truncate(holiday?.description, 20) }}
                          <v-btn
                            small
                            text
                            color="cyan"
                            class="font-weight-700"
                            @click="showDescription(holiday.description)"
                            >Read More</v-btn
                          >
                        </span>
                      </template>
                      <em v-else class="text-muted"> no description </em>
                    </td>
                    <td class="simple-table-td">
                      <v-icon
                        color="cyan"
                        size="20"
                        v-on:click="updateHoliday(holiday)"
                      >
                        mdi-pencil</v-icon
                      >
                      <v-icon
                        color="red"
                        class="ml-2"
                        size="20"
                        v-on:click="deleteHoliday(holiday)"
                      >
                        mdi-delete</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
                <tr v-else>
                  <td colspan="6" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no holiday at the moment.
                    </p>
                  </td>
                </tr>
                <!--  <tfoot v-else>
                  <tr v-if="holidayLoading">
                    <td
                      class="simple-table-td"
                      v-for="(di, did) in 5"
                      :key="did"
                    >
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton"
                      ></v-skeleton-loader>
                    </td>
                  </tr>
                  <tr v-else>
                    <td colspan="5" class="simple-table-td">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no holiday at the moment.
                      </p>
                    </td>
                  </tr>
                </tfoot> -->
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
    <Dialog :commonDialog="desc_dialog" :dialog-width="800">
      <template v-slot:title> Holiday Description </template>
      <template v-slot:body>
        <v-container fluid>
          <div>{{ fullDescription }}</div>
        </v-container>
      </template>

      <template v-slot:action>
        <v-btn
          depressed
          :disabled="pageLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="desc_dialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog="holidayDialog" :dialog-width="850">
      <template v-slot:title>
        <template v-if="holidayData.id">Update </template>
        <template v-else>Create </template>Holiday
      </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form
            ref="holidayForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="CreateHoliday"
          >
            <v-row>
              <v-col md="12">
                <label class="my-auto fw-500 required mb-1">Holiday Name</label>
                <TextField
                  id="quotation-title"
                  dense
                  filled
                  placeholder="Name"
                  solo
                  flat
                  counter="50"
                  v-model="holidayData.name"
                  color="cyan"
                  :maxlength="250"
                  :rules="[validateRules.required(holidayData.name, 'Name')]"
                />
              </v-col>
              <v-col md="6">
                <label class="my-auto fw-500 required mb-1">Holiday Date</label>
                <DatePicker
                  :pageLoading="pageLoading"
                  solo
                  :defaultDate="holidayData.date"
                  :placeholder="'Date'"
                  v-on:update:date-picker="setHolidayDate"
                ></DatePicker>
              </v-col>
              <v-col md="6">
                <label class="my-auto fw-500 mb-1 required"
                  >Allow jobs to be assigned on this day?</label
                >
                <v-autocomplete
                  hide-details
                  v-model.trim="holidayData.allow_job_assign"
                  :items="allow_job"
                  dense
                  flat
                  filled
                  item-color="cyan"
                  color="cyan"
                  solo
                  :rules="[
                    validateRules.required(
                      holidayData.allow_job_assign,
                      'Name'
                    ),
                  ]"
                  item-value="value"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Status(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 15px; width: 15px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                          <!-- ({{ item.all_ticket_visits }}) --></template
                        >
                        <template v-else
                          >{{ item.text }}
                          <!-- ({{
                            item.ticket_visit_status_count
                          }}) --></template
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <!--  <v-col md="4" v-if="holidayData.allow_job_assign == 2">
                <label class="my-auto fw-500 mb-1 required"
                  >In this a holiday for all users?</label
                >
                <v-autocomplete
                  hide-details
                  v-model.trim="holidayData.is_for_all_users"
                  :items="allow_job"
                  :rules="[
                    validateRules.required(
                      holidayData.is_for_all_users,
                      'Name'
                    ),
                  ]"
                  dense
                  flat
                  filled
                  item-color="cyan"
                  color="cyan"
                  solo
                  item-value="value"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Status(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 15px; width: 15px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                          </template
                        >
                        <template v-else
                          >{{ item.text }}
                          </template
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col> -->
              <v-col md="12" v-if="holidayData.allow_job_assign == 2">
                <label class="my-auto fw-500 required mb-1">Select Team</label>
                <v-autocomplete
                  hide-details
                  v-model.trim="holidayData.team"
                  :items="teams"
                  dense
                  flat
                  filled
                  item-color="cyan"
                  color="cyan"
                  solo
                  item-value="id"
                  multiple
                  item-text="team_name"
                  :rules="[
                    validateRules.required(holidayData.team, 'Team Name'),
                  ]"
                />
              </v-col>
              <v-col md="12">
                <label class="my-auto fw-500 mb-1">Description</label>
                <TextAreaField
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  v-model="holidayData.description"
                  placeholder="Description"
                  solo
                  flat
                  row-height="20"
                  counter="100"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :loading="createHolidayLoading"
          :disabled="pageLoading || createHolidayLoading || !formValid"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="CreateHoliday"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          :disabled="pageLoading || createHolidayLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="holidayDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <DeleteDialog :delete-dialog="deleteHolidayDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-18 font-weight-600 delete-text">
          Are you sure you want to delete this holiday ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="deleteVisitLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteHolidayDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          depressed
          :disabled="deleteVisitLoading"
          :loading="deleteVisitLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteVisitConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :commonDialog="holidayDuplicateDialog" :dialog-width="850">
      <template v-slot:title> Create Duplicate Calendar </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form
            ref="holidayDuplicateForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="CreateDuplicateHoliday"
          >
            <v-row>
              <v-col md="6">
                <label class="my-auto fw-500 required mb-1">From Year</label>
                <v-select
                  v-model="duplicateCalendar.from_year"
                  :items="yearList"
                  placeholder="From Year"
                  item-value="value"
                  item-text="text"
                  solo
                  flat
                  dense
                  filled
                  hide-details
                  item-color="cyan"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                ></v-select>
              </v-col>
              <v-col md="6">
                <label class="my-auto fw-500 required mb-1">To Year</label>
                <v-select
                  v-model="duplicateCalendar.to_year"
                  :items="yearList"
                  placeholder="To Year"
                  solo
                  flat
                  dense
                  filled
                  hide-details
                  item-value="value"
                  item-text="text"
                  item-color="cyan"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :loading="duplicateHolidayLoading"
          :disabled="pageLoading || duplicateHolidayLoading || !formValid"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="CreateDuplicateHoliday"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          :disabled="pageLoading || duplicateHolidayLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="holidayDuplicateDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import moment from "moment-timezone";
import { QUERY, POST, GET, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import { ErrorEventBus } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      createHolidayLoading: false,
      duplicateHolidayLoading: false,
      desc_dialog: false,
      fullDescription: "",
      holidayDialog: false,
      pageLoading: false,
      holidayLoading: false,
      deleteHolidayDialog: false,
      holidayDuplicateDialog: false,
      formValid: true,
      duplicateCalendar: {
        to_year: moment().add(1, "year").format("YYYY"),
        from_year: moment().format("YYYY"),
      },
      year: moment().format("YYYY"),
      holidays: [],
      allow_job: [
        {
          text: "Yes",
          value: 2,
          color: "green accent-4",
        },
        {
          text: "No",
          value: 1,
          color: "red accent-4",
        },
      ],
      teams: [],
      teamEngineers: [],
      deleteVisitLoading: false,
      holiday_id: [],
      holidayData: {
        date: new Date().toISOString().substr(0, 10),
        description: null,
        name: null,
        /*  type: 1, */
        allow_job_assign: 1,
        is_for_all_users: 1,
        /*      working: 1, */
        team: [],
        /*  users:[] */
      },
      yearList: [
        {
          value: moment().subtract(2, "year").format("YYYY"),
          text: moment().subtract(2, "year").format("YYYY"),
        },
        {
          value: moment().subtract(1, "year").format("YYYY"),
          text: moment().subtract(1, "year").format("YYYY"),
        },
        {
          value: moment().format("YYYY"),
          text: moment().format("YYYY"),
        },
        {
          value: moment().add(1, "year").format("YYYY"),
          text: moment().add(1, "year").format("YYYY"),
        },
        {
          value: moment().add(2, "year").format("YYYY"),
          text: moment().add(2, "year").format("YYYY"),
        },
      ],
    };
  },
  props: {
    internal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    holidayDialog() {
      this.holidayData = {
        date: new Date().toISOString().substr(0, 10),
        description: null,
        allow_job_assign: 1,
        is_for_all_users: 1,
        team: [],
      };
      /*  this.$refs.holidayForm.validate(); */
    },
  },
  components: {
    Dialog,
    DatePicker,
    TextAreaField,
    DeleteDialog,
  },
  methods: {
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    showDescription(description) {
      this.fullDescription = description;
      this.desc_dialog = true;
    },
    duplicateCalendarDialog() {
      this.holidayDuplicateDialog = true;
    },

    deleteHoliday(data) {
      this.holiday_id = data.id;
      this.deleteHolidayDialog = true;
    },
    deleteVisitConfirm() {
      const _this = this;
      _this.deleteVisitLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `holiday`,
          data: {
            selected: [this.holiday_id],
          },
        })
        .then(() => {
          _this.deleteVisitLoading = false;
          this.deleteHolidayDialog = false;
          _this.getHolidays();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    CreateDuplicateHoliday() {
      const _this = this;

      _this.duplicateHolidayLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "holiday-duplicate",
          data: _this.duplicateCalendar,
        })
        .then(() => {
          _this.getHolidays();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.holidayDuplicateDialog = false;
        });
    },
    CreateHoliday() {
      const _this = this;
      if (
        this.holidayData?.team?.length == 0 &&
        this.holidayData?.allow_job_assign == 2
      ) {
        ErrorEventBus.$emit("update:error", "Please Select Team.");
        return false;
      }
      _this.createHolidayLoading = true;
      _this.$store
        .dispatch(POST, { url: "holiday", data: _this.holidayData })
        .then(() => {
          _this.getHolidays();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.createHolidayLoading = false;
        });
    },
    setHolidayDate(param) {
      this.holidayData.date = param;
    },
    updateHoliday(param) {
      const _this = this;
      _this.getTeams();
      _this.holidayDialog = true;
      setTimeout(function () {
        _this.holidayData = {
          id: _this.lodash.toSafeInteger(param.id),
          date: param.date,
          name: param.name,
          allow_job_assign: _this.lodash.toSafeInteger(param.allow_job_assign),
          is_for_all_users: _this.lodash.toSafeInteger(param.is_for_all_users),
          team: param.team ? param.team : [],
          description: param.description ? param.description : null,
        };
      }, 500);
    },
    getHolidays() {
      const _this = this;
      _this.holidayLoading = true;
      _this.$store
        .dispatch(QUERY, { url: "holiday", data: { year: _this.year } })
        .then(({ data }) => {
          _this.holidays = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.holidayLoading = false;
          _this.holidayDialog = false;
        });
    },
    getTeams() {
      const _this = this;
      _this.holidayLoading = true;
      _this.$store
        .dispatch(GET, { url: "common-team" })
        .then(({ data }) => {
          _this.teams = data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.holidayLoading = false;
        });
    },
  },
  mounted() {
    this.getHolidays();
    this.getTeams();
  },
};
</script>
