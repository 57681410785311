<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-700 mb-4 font-size-24 custom-headline color-custom-blue"
        >
          Customer
        </h3>
      </v-flex>
      <div class="">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </v-layout>
    <v-row>
      <v-col md="10">
        <div>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 75vh; position: relative"
          >
            <CustomerBarcode> </CustomerBarcode>
            <!-- category listing -->
            <v-card
              flat
              class="custom-grey-border remove-border-radius mt-4"
              v-if="false"
            >
              <v-card-title class="headline grey lighten-4">
                <v-row>
                  <v-col md="4" class="py-0 my-auto">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                      style="margin: 3.5px 0px"
                    >
                      Category
                    </h3>
                  </v-col>
                  <template v-if="getPermission('setting:update')">
                    <v-col md="8" class="text-right py-0">
                      <div class="d-flex justify-content-end">
                        <v-btn
                          depressed
                          small
                          class="text-white"
                          color="cyan"
                          v-on:click="categoryDialogOpen('category')"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                        >
                          <span class="font-size-16 font-weight-600"
                            >Create</span
                          >
                        </v-btn>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>
              <v-card-text
                class="p-6 font-size-16"
                :class="{
                  'pointer-events-none': !getPermission('setting:update'),
                }"
              >
                <v-row>
                  <!-- :height="customerCategory?.length > 0 ? 'auto' : '100px'" -->
                  <v-col md="12" class="pb-4">
                    <v-simple-table
                      class="simple-table"
                      fixed-header
                      style="max-height: 350px"
                      :height="customerCategory?.length > 0 ? 'auto' : '100px'"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="simple-table-th" width="50">#</th>
                            <th class="simple-table-th" width="500">
                              Category
                            </th>
                            <th class="simple-table-th" width="70">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="customerCategory?.length > 0">
                          <tr
                            v-for="(category, index) in customerCategory"
                            :key="index"
                            class="alternate-listing-row"
                          >
                            <td width="50" class="simple-table-td">
                              {{ index + 1 }}
                            </td>
                            <td
                              width="100"
                              class="simple-table-td text-capitalize"
                            >
                              <template v-if="category?.text">
                                <v-chip
                                  small
                                  :color="category.color"
                                  class="ml-1 text-white mt-n1"
                                  label
                                  style="width: 6px !important"
                                >
                                </v-chip>
                                {{ category?.text }}
                              </template>
                              <em v-else class="text-muted"> no name </em>
                            </td>
                            <td class="simple-table-td">
                              <v-icon
                                color="cyan"
                                size="20"
                                v-on:click="
                                  updateCategoriesData(category, 'category')
                                "
                              >
                                mdi-pencil</v-icon
                              >
                              <v-icon
                                color="red"
                                class="ml-2"
                                size="20"
                                v-on:click="deleteSkills(category, 'category')"
                              >
                                mdi-delete</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                        <tr v-else>
                          <td colspan="5" class="py-4">
                            <p
                              class="m-0 row-not-found text-center font-weight-500 font-size-16"
                            >
                              <img
                                :src="$assetURL('media/error/empty.png')"
                                class="row-not-found-image"
                              />
                              Uhh... There are no category at the moment.
                            </p>
                          </td>
                        </tr>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              class="custom-grey-border remove-border-radius mt-4 page_table"
            >
              <v-card-title class="headline grey lighten-4">
                <v-row>
                  <v-col md="4" class="py-0 my-auto">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                      style="margin: 3.5px 0px"
                    >
                      Category
                    </h3>
                  </v-col>
                  <template v-if="getPermission('setting:update')">
                    <v-col md="8" class="text-right py-0">
                      <div class="d-flex justify-content-end">
                        <v-btn
                          depressed
                          small
                          class="text-white"
                          color="cyan"
                          v-on:click="categoryDialogOpen('category')"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                        >
                          <span class="font-size-16 font-weight-600"
                            >Create</span
                          >
                        </v-btn>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-card-title>
              <v-card-text
                class="p-6 font-size-16"
                :class="{
                  'pointer-events-none': !getPermission('setting:update'),
                }"
              >
                <v-row>
                  <v-col md="12" class="pb-4">
                    <v-data-table
                      :headers="headers"
                      :items="paginatedItems"
                      class="elevation-0 alter_color"
                      hide-default-footer
                    >
                      <template v-slot:item.index="{ index }">
                        <span class="text-capitalize">
                          {{ (currentPage - 1) * itemsPerPage + index + 1 }}
                        </span>
                      </template>
                      <template v-slot:item.text="{ item }">
                        <template v-if="item?.text">
                          <v-chip
                            small
                            :color="item.color"
                            class="text-white mt-n1"
                            label
                            style="width: 6px !important"
                          >
                          </v-chip>
                          <span class="text-capitalize">
                            {{ item?.text }}
                          </span>
                        </template>
                        <em v-else class="text-muted"> no name </em>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          color="cyan"
                          size="20"
                          v-on:click="updateCategoriesData(item, 'category')"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          color="red"
                          class="ml-2"
                          size="20"
                          :disabled="paginatedItems?.length == 1 ? true : false"
                          v-on:click="deleteSkills(item, 'category')"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <template v-slot:no-data>
                        <p
                          class="m-0 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no category at the moment.
                        </p>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row
                  justify="end"
                  class="align-center pagination_btn listing-footer px-2"
                >
                  <p class="m-0 listing-footer-count">
                    Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                    {{
                      Math.min(
                        currentPage * itemsPerPage,
                        customerCategory.length
                      )
                    }}
                    of {{ customerCategory.length }} entries
                  </p>
                  <v-spacer></v-spacer>
                  <v-pagination
                    v-model="currentPage"
                    :length="pageCount"
                    total-visible="7"
                    color="cyan"
                    @input="handlePageChange"
                  ></v-pagination>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- category listing -->
            <v-card
              flat
              class="custom-setting-height custom-grey-border remove-border-radius mt-4"
            >
              <v-card-title class="headline sticky-header grey lighten-4">
                <TagList internal module="customer"></TagList>
              </v-card-title>
            </v-card>
          </perfect-scrollbar>
        </div>
      </v-col>
    </v-row>
    <Dialog :commonDialog="categoryDialog" :dialog-width="850">
      <template v-slot:title>
        Create {{ related_type }}
        <!--   <template v-if="holidayData.id">Update </template>
  <template v-else>Create </template>Holiday -->
      </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form
            ref="ticketCollecttionFormSkills"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateCategories"
          >
            <v-row>
              <v-col md="12">
                <label class="my-auto fw-500 required mb-1">Title</label>
                <TextField
                  id="quotation-title"
                  dense
                  filled
                  placeholder="Name"
                  solo
                  flat
                  counter="50"
                  v-model="categoryPayload.text"
                  color="cyan"
                  :maxlength="250"
                  :rules="[
                    validateRules.required(categoryPayload.text, 'Title'),
                  ]"
                />
              </v-col>
              <v-col md="12">
                <label class="my-auto fw-500 required mb-1">Color</label>
                <v-color-picker
                  elevation="10"
                  v-model.trim="categoryPayload.color"
                  :swatches="swatches"
                  show-swatches
                  hide-canvas
                  hide-inputs
                  swatches-max-height="100"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :loading="dataLoading"
          :disabled="dataLoading || !formValid"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="updateAllSetting(is_exist)"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="categoryDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <DeleteDialog :delete-dialog="deleteCategoryDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-18 font-weight-600 delete-text">
          Are you sure you want to delete this
          <span class="text-capitalize">{{ related_type }}</span
          >?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="deleteJobLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteCategoryDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          depressed
          :disabled="deleteJobLoading"
          :loading="deleteJobLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteJobTypeConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <h3 class="font-weight-500 text-center">
              <span class="text-capitalize">{{ related_type }}</span> already
              exists in another transaction. Any updates will be reflected in a
              new transaction.
            </h3>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirm_loading"
          v-on:click="confirm_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="updateCategories(related_type)"
          :disabled="!formValid || confirm_loading"
          :loading="confirm_loading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import CustomerBarcode from "@/view/pages/profile/Setting/Partial/Customer-barcode";
import TagList from "@/view/pages/profile/Setting/Tags-List";
/*   import Color from "@/view/pages/engineer/detail/Color"; */

export default {
  mixins: [ValidationMixin, CommonMixin],
  name: "profile-service-setting",
  data() {
    return {
      summaryCerts: {
        skill: {
          active: 0,
          inactive: 0,
        },
        certificate: {
          active: 0,
          inactive: 0,
        },
        license: {
          active: 0,
          inactive: 0,
        },
      },
      formValid: true,
      categoryDialog: false,
      deleteJobLoading: false,
      related_id: null,
      is_exist: 0,
      related_type: null,
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      headers: [
        { text: "#", value: "index", width: "50px", sortable: false },
        { text: "Category", value: "text", width: "500px", sortable: false },
        { text: "Action", value: "actions", width: "70px", sortable: false },
      ],
      itemsPerPage: 10,
      currentPage: 1,
      categoryPayload: {
        id: null,
        text: null,
        is_active: 1,
        key_color: null,
        color: {
          alpha: 1,
          hex: "#00BCD4",
          hexa: "#00BCD4FF",
          hsla: {
            a: 1,
            h: 186.79245283018867,
            l: 0.41568627450980394,
            s: 1,
          },
          hsva: {
            a: 1,
            h: 186.79245283018867,
            s: 1,
            v: 0.8313725490196079,
          },
          hue: 186.79245283018867,
          rgba: {
            a: 1,
            b: 212,
            g: 188,
            r: 0,
          },
        },
      },
      deleteCategoryDialog: false,
      confirm_dialog: false,
      confirm_loading: false,
      customerCategory: [],
      serviceCertificate: [],
      serviceLicense: [],
      show_skills: true,
      show_licence: true,
      dataLoading: false,
      pageLoading: true,
    };
  },
  methods: {
    categoryDialogOpen(type) {
      if (type == "category") {
        this.related_type = "Category";
      } /* else if (type == "certificate") {
          this.related_type = "Certificate";
        } else if (type == "license") {
          this.related_type = "License";
        } */
      this.categoryPayload = {
        id: null,
        color: null,
        is_active: 1,
        text: null,
      };
      this.categoryDialog = true;
    },
    updateCategoriesData(param, type) {
      if (type == "category") {
        this.related_type = "category";
      } /* else if (type == "certificate") {
          this.related_type = "Certificate";
        } else if (type == "license") {
          this.related_type = "License";
        } */
      const _this = this;
      _this.categoryDialog = true;
      _this.is_exist = param.is_exist ? 1 : 0;
      setTimeout(function () {
        _this.categoryPayload = {
          id: _this.lodash.toSafeInteger(param.id),
          color: param.color ? param.color : null,
          is_active: param.is_active ? param.is_active : 1,
          text: param.text ? param.text : null,
        };
      }, 500);
      this.getServiceSetting();
    },
    deleteSkills(data, type) {
      this.related_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.related_type = type;
      this.deleteCategoryDialog = true;
    },
    deleteJobTypeConfirm() {
      const _this = this;
      _this.deleteJobLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `categories/delete/${this.related_id}`,
          data: {
            is_exist: this.is_exist,
          },
        })
        .then(() => {
          _this.deleteJobLoading = false;
          this.deleteCategoryDialog = false;
          this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteJobLoading = false;
        });
    },
    updateAllSetting(type) {
      if (type == 0) {
        this.updateCategories(this.related_type);
      } else {
        this.confirm_dialog = true;
      }
    },
    updateCategories(type) {
      const _this = this;
      this.$refs.ticketCollecttionFormSkills.validate();
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.dataLoading = false;
        }
        return false;
      }
      if (this.categoryPayload?.color?.hexa) {
        let colorCode = this.categoryPayload?.color?.hexa
          ? this.categoryPayload?.color?.hexa
          : null;
        this.categoryPayload.color = colorCode;
      }
      _this.dataLoading = true;
      let URL = "";
      let typeFormated = type.toLowerCase();
      if (typeFormated == "category") {
        URL = "categories/customer";
      } /* else if (typeFormated == "certificate") {
          URL = "setting/service-skills";
        } else if (typeFormated == "license") {
          URL = "setting/service-licence";
        } */
      _this.$store
        .dispatch(PATCH, {
          url: URL,
          data: _this.categoryPayload,
        })
        .then(() => {
          this.categoryDialog = false;
          _this.confirm_dialog = false;
          _this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.dataLoading = false;
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },

    getServiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "categories/customer" })
        .then(({ data }) => {
          _this.setting = data;
          _this.customerCategory = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    handlePageChange(page) {
      this.pageLoading = true;
      this.currentPage = page;
      // Simulate a delay to show loading state, replace with actual data fetch logic
      setTimeout(() => {
        this.pageLoading = false;
      }, 500);
    },
  },

  mounted() {
    this.getServiceSetting();
    /*   this.$refs.ticketCollecttionFormSkills.validate(); */
    /*   this.$refs.ticketCollecttionFormNew.validate(); */
  },
  components: {
    Dialog,
    DeleteDialog,
    CustomerBarcode,
    TagList,
  },
  computed: {
    pageCount() {
      return Math.ceil(this.customerCategory.length / this.itemsPerPage);
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.customerCategory.slice(start, end);
    },
  },
};
</script>

<style scoped></style>
