<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-700 mb-4 font-size-24 custom-headline color-custom-blue"
        >
          Service
        </h3>
      </v-flex>
      <div class="">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-row>
        <v-col md="10">
          <div>
            <ServiceBarcode> </ServiceBarcode>
          </div>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Service Settings
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateServiceSetting"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <table class="width-100 mt-0">
                    <tbody>
                      <tr>
                        <v-switch
                          v-model="service_skills_allow"
                          class="mt-0"
                          inset
                          label="Do you need Skills , Certificate & Licenses ?"
                          color="green"
                        ></v-switch>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div>
            <Part></Part>
          </div>
          <div>
            <Product></Product>
          </div>
        </v-col>
      </v-row>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import ServiceBarcode from "@/view/pages/profile/Setting/Partial/Service-barcode";
import Product from "@/view/pages/profile/Setting/Product";
import Part from "@/view/pages/profile/Setting/Part";
import { PATCH, GET } from "@/core/services/store/request.module";

export default {
  name: "profile-service-setting",
  data() {
    return {
      service_skills_allow: true,
      pageLoading: false,
    };
  },
  methods: {
    updateServiceSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/service-certs",
          data: {
            service_skills_allow: this.service_skills_allow ? 1 : 0,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getJobSetting();
          _this.pageLoading = false;
        });
    },
    getJobSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
          _this.service_skills_allow = data.service_skills_allow ? true : false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    ServiceBarcode,
    Product,
    Part,
  },
  mounted() {
    this.getJobSetting();
  },
};
</script>
