<template>
  <v-container
    class="tag-list-height"
    :class="internal ? 'pa-0' : ''"
    fluid
    v-if="getPermission('setting:view')"
  >
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-700 mb-4 font-size-24 custom-headline color-custom-blue"
        >
          Form Builder
        </h3>
      </v-flex>

      <div>
        <v-btn
          depressed
          small
          class="text-white mr-2"
          color="cyan"
          v-on:click="$router.push(getDefaultRoute('form-builder.create'))"
          :disabled="dataLoading"
          :loading="dataLoading"
        >
          <span class="font-size-16 font-weight-600">Create</span>
        </v-btn>
      </div>
      <div class="" v-if="!internal">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </v-layout>
    <ListingTable :columnCount="4" :rowData="rowData">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="10">
              <v-checkbox color="cyan" v-model="isCheckbox"></v-checkbox>
            </th>
            <th class="simple-table-th" width="40%">Title</th>
            <th class="simple-table-th">Job Type</th>
            <th class="simple-table-th">Pdf</th>
            <th class="simple-table-th">Added by</th>
            <th class="simple-table-th text-end" width="10%">Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData.length">
            <tr
              v-for="(row, index) in rowData"
              :key="index"
              class="alternate-listing-row"
            >
              <td class="simple-table-td pl-4">
                <v-checkbox color="cyan" v-model="row.isCheckbox"></v-checkbox>
              </td>
              <td class="simple-table-td text-capitalize pl-4">
                {{ row.title }}
              </td>
              <td class="simple-table-td text-capitalize pl-4">
                {{ row.job_type }}
              </td>
              <td class="simple-table-td pl-4">
                <v-icon color="blue" class="me-2" size="20">
                  mdi-file-pdf-box</v-icon
                >
                <v-icon color="red" size="20" v-on:click="downloadPdf">
                  mdi-download</v-icon
                >
              </td>
              <td class="simple-table-td pl-4">
                <TableActivity v-if="!lodash.isEmpty(row.added_by)" :data="row">
                  <template v-slot:display_name>
                    {{ row.added_by?.display_name }}
                  </template>
                  <template v-slot:date_time>
                    <template>{{ row.added_at }}</template>
                  </template>
                  <template v-slot:format_date_time>
                    {{ row.added_at }}
                  </template>
                </TableActivity>
              </td>
              <td class="simple-table-td text-end">
                <v-icon
                  color="cyan"
                  class="me-2"
                  size="20"
                  v-on:click="
                    $router.push(getDefaultRoute('form-builder.create'))
                  "
                >
                  mdi-pencil</v-icon
                >
                <v-icon color="red" size="20" v-on:click="deleteJob(job)">
                  mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no Form at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
      removePage
    ></ListingFooter>

    <DeleteDialog :delete-dialog="deleteCategoryDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-18 font-weight-600 delete-text">
          Are you sure you want to delete this
          <span class="text-capitalize">{{ related_type }}</span
          >?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="deleteJobLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteCategoryDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          depressed
          :disabled="deleteJobLoading"
          :loading="deleteJobLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteJobTypeConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <h3 class="font-weight-500 text-center">
              <span class="text-capitalize">{{ related_type }}</span> already
              exists in another transaction. Any updates will be reflected in a
              new transaction.
            </h3>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirm_loading"
          v-on:click="confirm_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="updateCategories(related_type)"
          :disabled="!formValid || confirm_loading"
          :loading="confirm_loading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
export default {
  mixins: [ValidationMixin, CommonMixin],
  name: "form-builder-setting",
  data() {
    return {
      type: null,
      isCheckbox: false,
      rowData: [
        {
          id: 1,
          isCheckbox: false,
          title: "title 1",
          job_type: "Installation",
          pdf: null,
          added_by: {
            display_name: "Admin",
          },
          added_at: "03/13/2024 10:12",
          created_at: "03/13/2024 10:12",
        },
        {
          id: 2,
          isCheckbox: false,
          title: "title 2",
          job_type: "Service",
          pdf: null,
          added_by: {
            display_name: "Admin",
          },
          added_at: "03/13/2024 10:12",
          created_at: "03/13/2024 10:12",
        },
      ],
      formValid: true,
      deleteJobLoading: false,
      related_id: null,
      is_exist: 0,
      related_type: null,
      type_filter: null,

      deleteCategoryDialog: false,
      confirm_dialog: false,
      confirm_loading: false,
      dataLoading: false,
      currentPage: 1,
      totalPages: 0,
      totalRows: 0,
    };
  },
  props: {
    internal: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
  },
  methods: {
    // formatDateSartTime(row) {
    //   return moment(row).format("hh:mm A");
    // },
    downloadPdf() {
      const pdfUrl = "https://example.com/sample.pdf";
      window.open(pdfUrl, "_blank");
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  components: {
    Dialog,
    DeleteDialog,
    ListingTable,
    ListingFooter,
    TableActivity,
  },
};
</script>
