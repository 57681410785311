<template>
  <v-container fluid class="" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-700 mb-4 font-size-24 custom-headline color-custom-blue"
        >
          Visit
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="10">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 75vh; position: relative"
        >
          <div>
            <VisitBarcode></VisitBarcode>
          </div>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Job Type
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-btn
                        depressed
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="jobTypeDialogOpen"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Create</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    style="min-height: 200px"
                    :height="jobTypeList?.length > 0 ? 'auto' : '100px'"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="200">Job Type</th>
                          <th class="simple-table-th">Description</th>
                          <th class="simple-table-th" width="350">Duration</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="paginatedJobTypeList.length">
                        <tr
                          v-for="(jobs, index) in paginatedJobTypeList"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{ (currentPage - 1) * itemsPerPage + index + 1 }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="jobs?.text">
                              <v-chip
                                small
                                :color="jobs.color"
                                class="ml-1 text-white mt-n1"
                                label
                                style="width: 6px !important"
                              >
                              </v-chip>
                              {{ jobs?.text }}
                            </template>
                            <em v-else class="text-muted"> no name </em>
                          </td>
                          <td class="simple-table-td">
                            <template v-if="jobs?.description">
                              <span class="d-flex align-baseline ml-2">
                                <span
                                  class="desc_para"
                                  v-html="truncate(jobs?.description, 50)"
                                ></span>
                                <v-btn
                                  small
                                  text
                                  color="cyan"
                                  class="font-weight-700"
                                  @click="showDescription(jobs.description)"
                                  >Read More</v-btn
                                >
                              </span>
                            </template>
                            <em v-else class="text-muted ml-2">
                              no description
                            </em>
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            {{ jobs?.day }} (Days)
                            <template v-if="jobs?.hour > 0"
                              >{{ jobs?.hour }} (Hours)</template
                            >
                          </td>
                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              @click="updatejobsType(jobs)"
                              >mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              @click="deletejobs(jobs)"
                              >mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="5" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no job types at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="align-center pagination_btn listing-footer px-2"
              >
                <p class="m-0 listing-footer-count">
                  Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                  {{ Math.min(currentPage * itemsPerPage, jobTypeList.length) }}
                  of {{ jobTypeList.length }} entries
                </p>
                <v-spacer></v-spacer>
                <v-pagination
                  v-model="currentPage"
                  :length="pageCount"
                  total-visible="7"
                  color="cyan"
                  @input="handlePageChange"
                ></v-pagination>
              </v-row>
            </v-card-text>

            <v-card-text
              v-if="false"
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <v-simple-table
                    class="simple-table"
                    fixed-header
                    :height="jobTypeList?.length > 0 ? 'auto' : '100px'"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="simple-table-th" width="50">#</th>
                          <th class="simple-table-th" width="500">Job Type</th>
                          <th class="simple-table-th">Description</th>
                          <th class="simple-table-th" width="350">Duration</th>
                          <th class="simple-table-th" width="70">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="jobTypeList?.length > 0">
                        <tr
                          v-for="(jobs, index) in jobTypeList"
                          :key="index"
                          class="alternate-listing-row"
                        >
                          <td width="50" class="simple-table-td">
                            {{ index + 1 }}
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            <template v-if="jobs?.text">
                              <v-chip
                                small
                                :color="jobs.color"
                                class="ml-1 text-white mt-n1"
                                label
                                style="width: 6px !important"
                              >
                              </v-chip>
                              {{ jobs?.text }}
                            </template>
                            <em v-else class="text-muted"> no name </em>
                          </td>
                          <td class="simple-table-td">
                            <template v-if="jobs?.description">
                              <span class="d-flex align-baseline">
                                <span
                                  class="desc_para"
                                  v-html="truncate(jobs?.description, 50)"
                                ></span>
                                <v-btn
                                  v-if="jobs?.description?.length > 50"
                                  small
                                  text
                                  color="cyan"
                                  class="font-weight-700"
                                  @click="showDescription(jobs.description)"
                                  >Read More</v-btn
                                >
                              </span>
                            </template>
                            <em v-else class="text-muted"> no description </em>
                          </td>
                          <td
                            width="100"
                            class="simple-table-td text-capitalize"
                          >
                            {{ jobs?.day }} (Days)
                            <template v-if="jobs?.hour > 0"
                              >{{ jobs?.hour }} (Hours)
                            </template>
                          </td>
                          <td class="simple-table-td">
                            <v-icon
                              color="cyan"
                              size="20"
                              v-on:click="updatejobsType(jobs)"
                            >
                              mdi-pencil</v-icon
                            >
                            <v-icon
                              color="red"
                              class="ml-2"
                              size="20"
                              v-on:click="deletejobs(jobs)"
                            >
                              mdi-delete</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tr v-else>
                        <td colspan="5" class="py-4">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no job type at the moment.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Schedule Combinations Types
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <!-- <v-checkbox
                    class="mx-4"
                    v-model="allow_break_type"
                    color="red white--text"
                    label="Show to Visits"
                  /> -->
                      <v-btn
                        depressed
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="updateCombination"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Update</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <table class="width-100 mt-0">
                    <tbody>
                      <tr>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.daily"
                            label="Daily"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.weekly"
                            label="Weakly"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.monthly"
                            label="Monthly"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.twice_a_month"
                            label="Twice A Month"
                          />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.bi_monthly"
                            label="Bi-Monthly"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.quarterly"
                            label="Quarterly"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.bi_annually"
                            label="Bi-Annually"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            color="cyan white--text"
                            v-model="combination.annually"
                            label="Annually"
                          />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            flat
            class="custom-grey-border remove-border-radius mt-4"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Break Types
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <div class="d-flex justify-content-end">
                      <v-checkbox
                        class="mx-4"
                        v-model="allow_break_type"
                        color="red white--text"
                        label="Show to Visits"
                      />
                      <v-btn
                        depressed
                        small
                        class="text-white"
                        color="cyan"
                        v-on:click="updateBreakTypes"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                      >
                        <span class="font-size-16 font-weight-600">Update</span>
                      </v-btn>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <table class="0">
                    <tbody>
                      <tr v-for="(data, index) in break_types" :key="index">
                        <v-switch
                          v-model="data.value"
                          class="mt-0"
                          inset
                          :label="data.field"
                          color="green"
                        ></v-switch>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Visit To Invoice
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateJobAppSettingInvoice"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <table class="0">
                    <tbody>
                      <tr>
                        <v-switch
                          v-model="setting.is_visit_invoice_allow"
                          class="mt-0"
                          inset
                          label="Is Invoice Needed"
                          color="green"
                          v-on:change="
                            setting.is_visit_invoice_allow
                              ? (setting.is_visit_recur_invoice_allow = false)
                              : (setting.is_visit_recur_invoice_allow = false)
                          "
                        ></v-switch>
                      </tr>
                      <tr>
                        <v-switch
                          v-model="setting.is_visit_recur_invoice_allow"
                          class="mt-0"
                          inset
                          label="Recurring Invoice allowed"
                          color="green"
                          :disabled="
                            !setting.is_visit_invoice_allow ? true : false
                          "
                        ></v-switch>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Show Application
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateJobAppSetting"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <table class="0">
                    <tbody>
                      <tr v-for="(data, index) in app_setting" :key="index">
                        <v-switch
                          v-model="data.value"
                          :readonly="data.autoload ? false : true"
                          v-on:change="changeEvent(index)"
                          class="mt-0"
                          inset
                          :label="getText(data.field)"
                          color="green"
                        ></v-switch>
                      </tr>
                      <!--  <tr>
                    <v-switch
                      v-model="allow_creatation"
                      class="mt-0"
                      inset
                      label="Visit Creation Allow In Application"
                      color="green"
                    ></v-switch>
                  </tr>
                  <tr>
                    <v-switch
                      v-model="self_allow"
                      class="mt-0"
                      inset
                      label="Self"
                      color="green"
                    ></v-switch>
                  </tr>
                  <tr>
                    <v-switch
                      v-model="all_technician"
                      class="mt-0"
                      inset
                      label="All technician"
                      color="green"
                    ></v-switch>
                  </tr> -->
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat class="custom-grey-border remove-border-radius mt-4">
            <v-card-title class="headline grey lighten-4">
              <v-row>
                <v-col md="4" class="py-0 my-auto">
                  <h3
                    class="font-weight-700 custom-headline color-custom-blue"
                    style="margin: 3.5px 0px"
                  >
                    Mobile Application
                  </h3>
                </v-col>
                <template v-if="getPermission('setting:update')">
                  <v-col md="8" class="text-right py-0">
                    <v-btn
                      depressed
                      small
                      class="text-white"
                      color="cyan"
                      v-on:click="updateServiceFormSetting"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text
              class="p-6 font-size-16"
              :class="{
                'pointer-events-none': !getPermission('setting:update'),
              }"
            >
              <v-row>
                <v-col md="12" class="pb-4">
                  <table class="0">
                    <tbody>
                      <tr>
                        <v-switch
                          v-model="allow_service_form"
                          class="mt-0"
                          inset
                          label="Service form required"
                          color="green"
                          v-on:change="settingAuto"
                        ></v-switch>
                      </tr>
                      <tr>
                        <v-switch
                          v-model="allow_stamp_note"
                          class="mt-0"
                          inset
                          :readonly="!allow_service_form"
                          label="Stamp Allowed In Service Form"
                          color="green"
                        ></v-switch>
                      </tr>
                      <tr>
                        <v-switch
                          v-model="allow_voice_note"
                          :readonly="!allow_service_form"
                          class="mt-0"
                          inset
                          label="Voice Note Allowed In Service Form"
                          color="green"
                        ></v-switch>
                      </tr>
                      <tr>
                        <v-switch
                          v-model="allow_is_image_editor"
                          :readonly="!allow_service_form"
                          class="mt-0"
                          inset
                          label="Image Editor Allowed In Service Form"
                          color="green"
                        ></v-switch>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <Note module="ticket"></Note>
          <v-card
            flat
            class="custom-setting-height custom-grey-border remove-border-radius mt-4"
          >
            <v-card-title class="headline sticky-header grey lighten-4">
              <TagList internal module="visit"></TagList>
            </v-card-title>
          </v-card>
          <Dialog :commonDialog="desc_dialog" :dialog-width="800">
            <template v-slot:title> Job Description </template>
            <template v-slot:body>
              <v-container fluid>
                <div v-html="fullDescription"></div>
              </v-container>
            </template>

            <template v-slot:action>
              <v-btn
                depressed
                :disabled="pageLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="desc_dialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
          <Dialog :commonDialog="skillDialog" :dialog-width="850">
            <template v-slot:title>
              Job Type
              <!--   <template v-if="jobsData.id">Update </template>
        <template v-else>Create </template>jobs -->
            </template>
            <template v-slot:body>
              <v-container fluid>
                <v-form
                  ref="ticketCollecttionFormSkills"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateJobSetting"
                >
                  <v-row>
                    <v-col md="12">
                      <label class="my-auto fw-500 required mb-1">Title</label>
                      <TextField
                        id="quotation-title"
                        dense
                        filled
                        placeholder="Name"
                        solo
                        flat
                        counter="50"
                        v-model="jobTypePayload.text"
                        color="cyan"
                        :maxlength="250"
                        :rules="[
                          validateRules.required(jobTypePayload.text, 'Name'),
                        ]"
                      />
                    </v-col>
                    <!--   <v-col md="12">
              <label class="my-auto fw-600">Duration</label>
            </v-col> -->
                    <v-col md="6">
                      <label class="my-auto fw-600 mb-1 required"
                        >Duration (Days)</label
                      >
                      <v-text-field
                        class="line-item-header-text px-0"
                        placeholder="Day"
                        dense
                        filled
                        type="number"
                        v-model="jobTypePayload.day"
                        solo
                        flat
                        v-on:keypress="isNumber($event)"
                        :rules="[
                          validateRules.required(jobTypePayload.day, 'day'),
                        ]"
                        color="cyan"
                      />
                    </v-col>
                    <v-col md="6">
                      <label class="my-auto fw-600 mb-1"
                        >Duration (Hours)</label
                      >
                      <v-autocomplete
                        dense
                        v-model="jobTypePayload.hour"
                        :items="hours"
                        item-text="text"
                        item-value="value"
                        item-color="cyan"
                        filled
                        hide-details
                        placeholder="Hours"
                        solo
                        flat
                        color="cyan"
                      ></v-autocomplete>
                      <!--  <v-text-field
                 :rules="[validateRules.required(jobTypePayload.hour, 'hour')]"
                  class="line-item-header-text px-0"
                  placeholder="Hours"
                  dense
                  filled
                  type="number"
                  v-model="jobTypePayload.hour"
                  solo
                  flat
                  v-on:keypress="isNumber($event)"
                  color="cyan"
                /> -->
                    </v-col>
                    <v-col md="5">
                      <label class="my-auto fw-500 required mb-1">Color</label>
                      <v-color-picker
                        elevation="10"
                        v-model.trim="jobTypePayload.color"
                        :swatches="swatches"
                        show-swatches
                        hide-canvas
                        hide-inputs
                        swatches-max-height="100"
                      ></v-color-picker>
                    </v-col>
                    <v-col md="7">
                      <label class="my-auto fw-500 mb-1">Description</label>
                      <!-- <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="jobTypePayload.description"
                    placeholder="Description"
                    solo
                    flat
                    row-height="20"
                    counter="250"
                  /> -->
                      <tiny-mce
                        :disabled="formLoading"
                        v-model="jobTypePayload.description"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </template>
            <template v-slot:action>
              <v-btn
                depressed
                :loading="pageLoading"
                :disabled="pageLoading || !formValid"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="updateJobTypeSetting(is_exist)"
              >
                Save
              </v-btn>
              <v-btn
                depressed
                :disabled="pageLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="skillDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
          <DeleteDialog :delete-dialog="deletejobDialog">
            <template v-slot:title>Delete Confirmation</template>
            <template v-slot:text>
              <h3 class="font-size-18 font-weight-600 delete-text">
                Are you sure you want to delete this job type ?
              </h3>
            </template>
            <template v-slot:action>
              <v-btn
                depressed
                :disabled="deleteJobLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="deletejobDialog = false"
                >No! Cancel</v-btn
              >
              <v-btn
                depressed
                :disabled="deleteJobLoading"
                :loading="deleteJobLoading"
                color="red lighten-1 white--text"
                v-on:click="deleteJobTypeConfirm()"
                class="mx-2 custom-grey-border custom-bold-button"
                >Yes! Delete</v-btn
              >
            </template>
          </DeleteDialog>
          <Dialog :common-dialog="confirm_dialog" :dialog-width="850">
            <template v-slot:title> Confirmation </template>
            <template v-slot:body>
              <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
                <v-container class="px-10" fluid>
                  <h3 class="font-weight-500 text-center">
                    Job Type already exists in another transaction. Any updates
                    will be reflected in a new transaction.
                  </h3>
                </v-container>
              </v-form>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="confirm_loading"
                v-on:click="confirm_dialog = false"
                class="mx-2 custom-grey-border custom-bold-button"
              >
                No! Close
              </v-btn>
              <v-btn
                v-on:click="updateJobSetting()"
                :disabled="!formValid || confirm_loading"
                :loading="confirm_loading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
              >
                Yes! Continue
              </v-btn>
            </template>
          </Dialog>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import VisitBarcode from "@/view/pages/profile/Setting/Partial/Visit-barcode";
import Note from "@/view/pages/profile/Setting/Note";
import TagList from "@/view/pages/profile/Setting/Tags-List";

export default {
  name: "profile-job-setting",
  mixins: [ValidationMixin],
  data() {
    return {
      allow_break_type: false,
      pageLoading: true,
      deleteJobLoading: false,
      skillDialog: false,
      confirm_loading: false,
      confirm_dialog: false,
      desc_dialog: false,
      fullDescription: "",
      itemsPerPage: 10,
      currentPage: 1,
      is_exist: 0,
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      hours: [],
      combination: {
        daily: true,
        weekly: true,
        monthly: true,
        twice_a_month: true,
        bi_monthly: true,
        quarterly: true,
        bi_annually: true,
        annually: true,
      },
      setting: {
        is_visit_invoice_allow: true,
        is_visit_recur_invoice_allow: true,
      },
      deletejobDialog: false,
      job_type_id: 0,
      allow_service_form: false,
      allow_voice_note: false,
      allow_is_image_editor: false,
      allow_stamp_note: false,
      app_setting: [],
      break_types: [],
      jobTypePayload: {
        id: null,
        type: 13,
        field: "ticket-type",
        day: 1,
        hour: 1,
        value: null,
        entity: 0,
        autoload: 0,
        size: null,
        dimensions: null,
        text: null,
        is_active: 1,
        description: null,
        duration: null,
        key_color: null,
        color: {
          alpha: 1,
          hex: "#00BCD4",
          hexa: "#00BCD4FF",
          hsla: {
            a: 1,
            h: 186.79245283018867,
            l: 0.41568627450980394,
            s: 1,
          },
          hsva: {
            a: 1,
            h: 186.79245283018867,
            s: 1,
            v: 0.8313725490196079,
          },
          hue: 186.79245283018867,
          rgba: {
            a: 1,
            b: 212,
            g: 188,
            r: 0,
          },
        },
      },
      jobTypeList: [],
    };
  },
  components: {
    Dialog,
    DeleteDialog,
    VisitBarcode,
    TagList,
    Note,
    "tiny-mce": TinyMCE,
  },
  methods: {
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    showDescription(description) {
      this.fullDescription = description;
      this.desc_dialog = true;
    },
    updatejobsType(param) {
      const _this = this;
      _this.is_exist = param.is_exist ? 1 : 0;
      _this.skillDialog = true;
      setTimeout(function () {
        _this.jobTypePayload = {
          id: _this.lodash.toSafeInteger(param.id),
          type: 13,
          field: "ticket-type",
          value: param?.value ? param.value : null,
          color: param.color ? param.color : null,
          duration: param.duration ? param.duration : null,
          description: param.description ? param.description : null,
          is_active: param.is_active ? param.is_active : 1,
          hour: param.hour ? _this.lodash.toSafeInteger(param.hour) : 1,
          day: param.day ? _this.lodash.toSafeInteger(param.day) : 1,
          text: param.text ? param.text : null,
        };
      }, 500);
      this.getServiceSetting();
    },
    getServiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/service" })
        .then(({ data }) => {
          if (Array.isArray(data.job_types) && data.job_types.length) {
            _this.jobTypeList = data?.job_types;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getText(text) {
      if (text == "allow_creatation") {
        return "Visit Creation Allow In Application";
      } else if (text == "self_allow") {
        return "Self";
      } else if (text == "all_technician") {
        return "All technician";
      }
    },
    settingAuto() {
      if (!this.allow_service_form) {
        (this.allow_voice_note = false), (this.allow_stamp_note = false);
        this.allow_is_image_editor = false;
      }
    },
    updateJobTypeSetting(type) {
      if (type == 0) {
        this.updateJobSetting();
      } else {
        this.confirm_dialog = true;
      }
    },
    getJobSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
          _this.setting.is_visit_recur_invoice_allow =
            data.is_visit_recur_invoice_allow;
          _this.setting.is_visit_invoice_allow = data.is_visit_invoice_allow;
          _this.app_setting = data.app_setting;
          _this.combination = data.visit_combination;
          _this.break_types = data.breaks;
          _this.allow_break_type = data.allow_break_type;
          this.allow_service_form =
            data.allow_service_form && data.allow_service_form == 1
              ? true
              : false;
          this.allow_stamp_note =
            data.allow_stamp_note && data.allow_stamp_note == 1 ? true : false;
          this.allow_voice_note =
            data.allow_voice_note && data.allow_voice_note == 1 ? true : false;
          this.allow_is_image_editor =
            data.allow_is_image_editor && data.allow_is_image_editor == 1
              ? true
              : false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    deletejobs(data) {
      this.job_type_id = data.id;
      this.is_exist = data.is_exist ? 1 : 0;
      this.deletejobDialog = true;
    },
    deleteJobTypeConfirm() {
      const _this = this;
      _this.deleteJobLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `job/type`,
          data: {
            id: this.job_type_id,
            is_exist: this.is_exist,
          },
        })
        .then(() => {
          _this.deleteJobLoading = false;
          this.deletejobDialog = false;
          this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
          _this.deleteJobLoading = false;
        });
    },
    updateJobSetting() {
      const _this = this;
      const formErrors = _this.validateForm(
        _this.$refs.ticketCollecttionFormSkills
      );

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          _this.pageLoading = false;
        }
        return false;
      }
      if (this.jobTypePayload?.color?.hexa) {
        let colorCode = this.jobTypePayload?.color?.hexa
          ? this.jobTypePayload?.color?.hexa
          : null;
        this.jobTypePayload.color = colorCode;
      }
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/service-job_types",
          data: _this.jobTypePayload,
        })
        .then(() => {
          _this.skillDialog = false;
          _this.confirm_dialog = false;
          _this.getServiceSetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    jobTypeDialogOpen() {
      this.jobTypePayload = {
        id: null,
        type: 13,
        field: "ticket-type",
        value: null,
        color: null,
        duration: null,
        description: null,
        is_active: 1,
        hour: 1,
        day: 1,
        text: null,
      };
      this.skillDialog = true;
    },
    updateJobAppSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/job-app",
          data: {
            app_setting: this.app_setting,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getJobSetting();
        });
    },
    updateJobAppSettingInvoice() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/job-invoice", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getJobSetting();
        });
    },
    updateBreakTypes() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/break-types",
          data: {
            breaks: this.break_types,
            allow_break_type: this.allow_break_type,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getJobSetting();
        });
    },
    updateCombination() {
      const _this = this;
      const hasAtLeastOneTrue = Object.values(this.combination).some(
        (value) => value === true
      );
      if (!hasAtLeastOneTrue) {
        ErrorEventBus.$emit(
          "update:error",
          "Please select atleast one combination"
        );
        return false;
      }
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/visit-combination",
          data: this.combination,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getJobSetting();
        });
    },
    updateServiceFormSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/job-service-app",
          data: {
            allow_service_form: this.allow_service_form ? 1 : 0,
            allow_stamp_note: this.allow_stamp_note ? 1 : 0,
            allow_voice_note: this.allow_voice_note ? 1 : 0,
            allow_is_image_editor: this.allow_is_image_editor ? 1 : 0,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getJobSetting();
        });
    },

    changeEvent(index) {
      if (index == 0) {
        if (this.app_setting[0].value) {
          this.app_setting[1].autoload = 1;
          this.app_setting[2].autoload = 1;
          this.app_setting[1].value = true;
        } else {
          this.app_setting[1].autoload = 0;
          this.app_setting[2].autoload = 0;
          this.app_setting[1].value = false;
          this.app_setting[2].value = false;
        }
      }
      if (index == 1) {
        if (this.app_setting[1].value) {
          this.app_setting[2].value = false;
        } else {
          this.app_setting[2].value = true;
        }
      }
      if (index == 2) {
        if (this.app_setting[2].value) {
          this.app_setting[1].value = false;
        } else {
          this.app_setting[1].value = true;
        }
      }
    },
    generateHours() {
      for (let i = 0; i < 24; i++) {
        let hour = {
          value: i,
          text: i.toString().padStart(2, "0") + ":00", // Format the hour as '00:00'
        };
        this.hours.push(hour);
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
  },

  mounted() {
    this.getJobSetting();
    this.getServiceSetting();
    this.generateHours();
  },
  computed: {
    pageCount() {
      return Math.ceil(this.jobTypeList.length / this.itemsPerPage);
    },
    paginatedJobTypeList() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.jobTypeList.slice(start, end);
    },
  },
};
</script>
<style scoped>
label.v-label.theme--light {
  font-weight: 600 !important;
  color: black !important;
}
.desc_para {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
